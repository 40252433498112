import React from "react"
import SEO from "../components/seo"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import Container from "react-bootstrap/Container"
import Jumbotron from 'react-bootstrap/Jumbotron'
import Alert from 'react-bootstrap/Alert'
import Badge from 'react-bootstrap/Badge'
import "./materials.scss"
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import ShowMoreText from 'react-show-more-text';
import { window } from "browser-monads"
import Img from "gatsby-image"


export default function Sale() {
  const data = useStaticQuery(graphql`
  query {
    whiteKitchenImageOne: file(relativePath: { eq: "white-kitchen-w-island/white-kitchen-w-island-1.jpeg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 700) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    whiteKitchenImageTwo: file(relativePath: { eq: "white-kitchen-w-island/white-kitchen-w-island-2.jpeg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 700) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    whiteKitchenImageThree: file(relativePath: { eq: "white-kitchen-w-island/white-kitchen-w-island-3.jpeg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 700) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    whiteKitchenImageFour: file(relativePath: { eq: "white-kitchen-w-island/white-kitchen-w-island-4.jpeg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 700) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    whiteKitchenImageFive: file(relativePath: { eq: "white-kitchen-w-island/white-kitchen-w-island-5.jpeg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 700) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    kitchenGlassLaminateImageOne: file(relativePath: { eq: "kitchen-w-glass-laminate-facades/kitchen-w-glass-laminate-facades-1.jpeg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 700) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    kitchenGlassLaminateImageTwo: file(relativePath: { eq: "kitchen-w-glass-laminate-facades/kitchen-w-glass-laminate-facades-2.jpeg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 700) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    cupboardNovaRehauImageOne: file(relativePath: { eq: "cupboard-nova-rehau/cupboard-nova-rehau-1.jpeg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 700) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    cupboardNovaRehauImageTwo: file(relativePath: { eq: "cupboard-nova-rehau/cupboard-nova-rehau-2.jpeg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 700) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    cupboardNovaRehauImageThree: file(relativePath: { eq: "cupboard-nova-rehau/cupboard-nova-rehau-3.jpeg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 700) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    cupboardNovaRehauImageFour: file(relativePath: { eq: "cupboard-nova-rehau/cupboard-nova-rehau-4.jpeg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 700) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    slimSystemImageOne: file(relativePath: { eq: "slim-system/slim-system-1.jpeg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 700) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
  `)
  return (

    <Layout>
      <SEO
        title="Распродажа"
        keywords={[]}
        description=""
      />
      <h1 className="text-center">
        Распродажа
    </h1>
      <Container>

        <div className="card-group vgr-cards">
          <div className="card py-3">
            <div className="card-img-body">

              <Carousel showThumbs={false} dynamicHeight={false} autoPlay={true} infiniteLoop={true}>
                <div>
                  <Img fluid={data.whiteKitchenImageOne.childImageSharp.fluid} />
                  {/* <p className="legend">Legend 1</p> */}
                </div>
                <div>
                  <Img fluid={data.whiteKitchenImageTwo.childImageSharp.fluid} />
                  {/* <p className="legend">Legend 2</p> */}
                </div>
                <div>
                  <Img fluid={data.whiteKitchenImageThree.childImageSharp.fluid} />
                  {/* <p className="legend">Legend 3</p> */}
                </div>
                <div>
                  <Img fluid={data.whiteKitchenImageFour.childImageSharp.fluid} />
                  {/* <p className="legend">Legend 4</p> */}
                </div>
                <div>
                  <Img fluid={data.whiteKitchenImageFive.childImageSharp.fluid} />
                  {/* <p className="legend">Legend 5</p> */}
                </div>
              </Carousel>

            </div>
            <div className="card-body">
              <Jumbotron>
                <h2 className="card-title h2">Кухня Белая с островом <Badge variant="success" as="div">-35%</Badge></h2>




                <p className="card-text">
                  <div class="style-1">
                    <del>
                      <span class="amount">434680&nbsp;руб.</span>
                    </del><br />
                    <ins>
                      <span class="amount">282550&nbsp;руб.</span>
                    </ins>
                  </div>



                  <ShowMoreText
                    /* Default options */
                    lines={1}
                    more='Читать дальше'
                    less='Скрыть текст'
                    anchorClass='text-dark underline-text'
                    expanded={false}
                  >
                    <h3 className="mt-5">Дополнительно:</h3> <br />
                    <h2 className="card-title">Портал шпон <Badge variant="success" as="div">-35%</Badge></h2>
                    <div class="style-1">
                      <del>
                        <span class="amount">65000&nbsp;руб.</span>
                      </del><br />
                      <ins>
                        <span class="amount">42250&nbsp;руб.</span>
                      </ins>
                    </div>



                    <h2 className="card-title mt-4">Столешница <Badge variant="success" as="div">-35%</Badge></h2>
                    <div class="style-1">
                      <del>
                        <span class="amount">566841&nbsp;руб.</span>
                      </del><br />
                      <ins>
                        <span class="amount">368440&nbsp;руб.</span>
                      </ins>
                    </div>


                    <h2 className="card-title mt-4">Мойка <Badge variant="success" as="div">-30%</Badge></h2>
                    <div class="style-1">
                      <del>
                        <span class="amount">37300&nbsp;руб.</span>
                      </del><br />
                      <ins>
                        <span class="amount">26100&nbsp;руб.</span>
                      </ins>
                    </div>


                    <h2 className="card-title mt-4">Смеситель <Badge variant="success" as="div">-30%</Badge></h2>
                    <div class="style-1">
                      <del>
                        <span class="amount">14300&nbsp;руб.</span>
                      </del><br />
                      <ins>
                        <span class="amount">10000&nbsp;руб.</span>
                      </ins>
                    </div>


                    <h2 className="card-title mt-4">Духовка <Badge variant="success" as="div">-30%</Badge></h2>
                    <div class="style-1">
                      <del>
                        <span class="amount">69000&nbsp;руб.</span>
                      </del><br />
                      <ins>
                        <span class="amount">48300&nbsp;руб.</span>
                      </ins>
                    </div>

                    <h2 className="card-title mt-4">Кофемашина <Badge variant="success" as="div">-30%</Badge></h2>
                    <div class="style-1">
                      <del>
                        <span class="amount">184000&nbsp;руб.</span>
                      </del><br />
                      <ins>
                        <span class="amount">128800&nbsp;руб.</span>
                      </ins>
                    </div>



                    <h2 className="card-title mt-4">Подогреватель посуды <Badge variant="success" as="div">-40%</Badge></h2>
                    <div class="style-1">
                      <del>
                        <span class="amount">59000&nbsp;руб.</span>
                      </del><br />
                      <ins>
                        <span class="amount">35400&nbsp;руб.</span>
                      </ins>
                    </div>

                    <h2 className="card-title mt-4">Холодильник <Badge variant="success" as="div">-30%</Badge></h2>
                    <div class="style-1">
                      <del>
                        <span class="amount">65000&nbsp;руб.</span>
                      </del><br />
                      <ins>
                        <span class="amount">45400&nbsp;руб.</span>
                      </ins>
                    </div>


                  </ShowMoreText>
                </p>
                <Alert variant="info">
                  Для более развёрнутой информации по материалам, фурнитуре и стоимости звоните:  <b>+79990051925</b> или приезжайте в салон на <b>ул. Варшавская, д. 3.</b>
                </Alert>

              </Jumbotron>
            </div>
          </div>
          <div className="card py-3">
            <div className="card-img-body">

              <Carousel showThumbs={false} dynamicHeight={false} autoPlay={true} infiniteLoop={true}>
                <div>
                  <Img fluid={data.kitchenGlassLaminateImageOne.childImageSharp.fluid} />
                  {/* <p className="legend">Legend 1</p> */}
                </div>
                <div>
                  <Img fluid={data.kitchenGlassLaminateImageTwo.childImageSharp.fluid} />
                  {/* <p className="legend">Legend 2</p> */}
                </div>
              </Carousel>

            </div>
            <div className="card-body">
              <Jumbotron>
                <h2 className="card-title h2">Кухня с фасадами из стеклоламината Rehau и каменного шпона <Badge variant="success" as="div">-40%</Badge></h2>




                <p className="card-text">
                  <div class="style-1">
                    <del>
                      <span class="amount">863780&nbsp;руб.</span>
                    </del><br />
                    <ins>
                      <span class="amount">518270&nbsp;руб.</span>
                    </ins>
                  </div>



                  <ShowMoreText
                    /* Default options */
                    lines={1}
                    more='Читать дальше'
                    less='Скрыть текст'
                    anchorClass='text-dark underline-text'
                    expanded={false}
                  >
                    <h3 className="mt-5">Дополнительно:</h3> <br />
                    <h2 className="card-title">Смеситель <Badge variant="success" as="div">-20%</Badge></h2>
                    <div class="style-1">
                      <del>
                        <span class="amount">22000&nbsp;руб.</span>
                      </del><br />
                      <ins>
                        <span class="amount">17600&nbsp;руб.</span>
                      </ins>
                    </div>

                    <h2 className="card-title mt-4">Столешница с интегрированной мойкой из кварцевого агломерата <Badge variant="success" as="div">-20%</Badge></h2>
                    <div class="style-1">
                      <del>
                        <span class="amount">221950&nbsp;руб.</span>
                      </del><br />
                      <ins>
                        <span class="amount">177560&nbsp;руб.</span>
                      </ins>
                    </div>
                  </ShowMoreText>
                </p>
                <Alert variant="info">
                  Для более развёрнутой информации по материалам, фурнитуре и стоимости звоните:  <b>+79990051925</b> или приезжайте в салон на <b>ул. Варшавская, д. 3.</b>
                </Alert>

              </Jumbotron>
            </div>
          </div>
          <div className="card py-3">
            <div className="card-img-body">

              <Carousel showThumbs={false} dynamicHeight={false} autoPlay={true} infiniteLoop={true}>
                <div>
                  <Img fluid={data.cupboardNovaRehauImageOne.childImageSharp.fluid} />
                  {/* <p className="legend">Legend 1</p> */}
                </div>
                <div>
                  <Img fluid={data.cupboardNovaRehauImageTwo.childImageSharp.fluid} />
                  {/* <p className="legend">Legend 2</p> */}
                </div>
                <div>
                  <Img fluid={data.cupboardNovaRehauImageThree.childImageSharp.fluid} />
                  {/* <p className="legend">Legend 3</p> */}
                </div>
                <div>
                  <Img fluid={data.cupboardNovaRehauImageFour.childImageSharp.fluid} />
                  {/* <p className="legend">Legend 4</p> */}
                </div>
              </Carousel>

            </div>
            <div className="card-body">
              <Jumbotron>
                <h2 className="card-title h2">Шкаф с системой Nova (двери из стеклоламината REHAU) <Badge variant="success" as="div">-50%</Badge></h2>




                <p className="card-text">
                  <div class="style-1">
                    <del>
                      <span class="amount">575500&nbsp;руб.</span>
                    </del><br />
                    <ins>
                      <span class="amount">278750&nbsp;руб.</span>
                    </ins>
                  </div>
                  <ShowMoreText
                    /* Default options */
                    lines={1}
                    more='Читать дальше'
                    less='Скрыть текст'
                    anchorClass='text-dark underline-text'
                    expanded={false}
                  >
                    <h3 className="mt-5">Дополнительно:</h3> <br />
                    <h2 className="card-title mt-4">Сборка</h2>
                    <div class="style-1">
                      <ins>
                        <span class="amount">23000&nbsp;руб.</span>
                      </ins>
                    </div>
                  </ShowMoreText>
                </p>
              </Jumbotron>
            </div>
          </div>
          <div className="card py-3">
            <div className="card-img-body">

              <Carousel showThumbs={false} dynamicHeight={false} autoPlay={false} infiniteLoop={true} indicators={false} controls={false}>
                <div>
                  <Img fluid={data.slimSystemImageOne.childImageSharp.fluid} />
                  {/* <p className="legend">Legend 1</p> */}
                </div>
              </Carousel>

            </div>
            <div className="card-body">
              <Jumbotron>
                <h2 className="card-title h2">Итальянская система SLIM <Badge variant="success" as="div">-50%</Badge></h2>




                <p className="card-text">
                  <div class="style-1">
                    <del>
                      <span class="amount">338811&nbsp;руб.</span>
                    </del><br />
                    <ins>
                      <span class="amount">169400&nbsp;руб.</span>
                    </ins>
                  </div>
                  <ShowMoreText
                    /* Default options */
                    lines={1}
                    more='Читать дальше'
                    less='Скрыть текст'
                    anchorClass='text-dark underline-text'
                    expanded={false}
                  >
                    <h3 className="mt-5">Дополнительно:</h3> <br />
                    <h2 className="card-title mt-4">Сборка</h2>
                    <div class="style-1">
                      <ins>
                        <span class="amount">15500&nbsp;руб.</span>
                      </ins>
                    </div>
                  </ShowMoreText>
                </p>
              </Jumbotron>
            </div>
          </div>
        </div>

      </Container>
    </Layout>
  )

}